import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ArrowRight } from "react-feather";
import { PageBlockTitle, staticImgContainer, CTALink, PageBlockNextPageDivider, primaryColour } from "../../styles/component-stylings";

const PAGE_TITLE = "Culture";

const CareersCulturePage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='An overview of the work culture at Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>We have one of the most balanced work cultures in the public service.</p>
                <p>We strongly encourage a casual and flexible workplace, where learning and sharing of ideas is free-flowing and available.</p>
                <p>We are focused on balancing work with life, realising that if our people are supported and happy, it will show in their work.</p>

                <PageBlockTitle>Casual environment</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center bottom" }}
                    src='../../images/casual-workwear.jpg'
                    alt='Casual Work Environment'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>We may work on some sensitive material, but our work environment is friendly, casual and a hub for creativity and ingenuity.</p>
                <p>Our work attire is also casual. Feel like wearing a nice blazer? Go for it. Feel like wearing a hoodie? Go for it.</p>

                <PageBlockTitle>Open lines of communication</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 65%" }}
                    src='../../images/conversation.jpg'
                    alt='Men Conversing'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    We are fortunate that DSC leadership is very hands-on and love being among the work. We have an open communication culture where anyone can
                    approach and freely chat with upper management to discuss operational ideas or trade funny anecdotes.
                </p>

                <p>Our leadership really cares and knows its people, fostering a flexible and inviting atmosphere.</p>

                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/careers/our-people' rel='next'>
                        <span>Our people</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default CareersCulturePage;
